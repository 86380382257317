import React, { useState } from 'react'
import Img from "gatsby-image"
import "./AboutUs.css";


function AboutUs(props) {
    const data = props;

    return (
        <div className="about-container">
            <div className="container m-auto py-16 flex flex-wrap text-white">
                <div className="w-full lg:w-1/2 text-center ab-left-tab px-4 md:px-0">
                    <h2 className="text-4xl">
                        {data.title}
                    </h2>
                    <p className="text-2xl">
                        {data.paragraph}
                    </p>
                    <p className="pt-4">
                        {data.subParagraph}
                    </p>
                    <div className="mt-8">
                        <a href={data.cta.url} className="about-cta float-none md:float-left px-6 py-3 ease-linear" >
                            { data.cta.label }
                        </a>
                    </div>
                </div>
                <div className="w-full mt-12 lg:mt-0 lg:w-1/2">
                    <Img fluid={data.aboutImage.imageFile.childImageSharp.fluid} alt={data.aboutImage.altText} className="m-auto" />
                </div>
            </div>
        </div>
    )
}

export default AboutUs;