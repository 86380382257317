import React, { useState } from 'react'
import Img from "gatsby-image"
import "./OurValues.css";


function OurValues(props) {
    const data = props;

    console.log(data)

    return (
        <div className="container m-auto py-16">
            <h2 className="text-3xl text-center">
                {data.title}
            </h2>
            <div className="flex text-center my-8 ab-inner-container flex-wrap">
                { data.values.map((val, index) => (
                    <div className={ index === 0 ? "w-full md:w-1/3 p-8" : "w-full md:w-1/3 p-8 border-left-gray"} key={`our-value-${index}`}>
                        <Img fixed={val.valueIcon.imageFile.childImageSharp.fixed} alt={val.valueIcon.altText} className="m-auto" />

                        <h3 className="text-lg font-bold mt-4">
                            { val.valueTitle}
                        </h3>
                        <p className="ab-value" >
                            { val.valueParagraph }
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OurValues;