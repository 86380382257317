import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from "../components/Global/Introduction"
import AboutUs from "../components/AboutUs/AboutUs"
import Philosophy from "../components/AboutUs/Philosophy"
import OurValues from "../components/AboutUs/OurValues"
// import OurTeam from "../components/AboutUs/OurTeam"
import Breadcrumb from "../components/Global/Breadcrumb"



function AboutUsTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.page.aboutPageAcf.introductionBackgroundImage} title={data.page.aboutPageAcf.introductionHeader} />
                    </section> */}
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} />
                        </section>
                    )}
                    <section>
                        <AboutUs
                            title={data.page.aboutPageAcf.aboutTitle}
                            paragraph={data.page.aboutPageAcf.aboutFirstParagraph}
                            subParagraph={data.page.aboutPageAcf.aboutSecondParagraph}
                            aboutImage={data.page.aboutPageAcf.aboutImage}
                            cta={data.page.aboutPageAcf.aboutCta}
                        />
                    </section>
                    <section style={{ backgroundColor: "rgb(240, 240, 240)" }}>
                        <Philosophy content={data.page.aboutPageAcf.philosophyContent} />
                    </section>
                    <section>
                        <OurValues title={data.page.aboutPageAcf.ourValuesTitle} values={data.page.aboutPageAcf.ourValue} />
                    </section>
                    {/* <section className="at-container">
                        <OurTeam title={data.page.aboutPageAcf.ourTeamTitle} members={data.teamMembers.edges} />
                    </section> */}
                </div>
            </Layout>
        </div>
    )
}

export default AboutUsTemplate

export const pageQuery = graphql`
    query GET_ABOUT_US($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum ) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    aboutPageAcf{
                    aboutTitle
                    aboutFirstParagraph
                    aboutSecondParagraph
                    aboutCta{
                        label
                        url
                    }
                    aboutImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 600){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    philosophyContent
                    ourValuesTitle
                    ourValue{
                        valueTitle
                        valueParagraph
                        valueIcon{
                            sourceUrl
                            altText
                            imageFile{
                                childImageSharp{
                                    fixed(width: 40){
                                        base64
                                        width
                                        height
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                    }
                    ourTeamTitle
                }
            }
            teamMembers(first: 200, where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}) {
                ...OurTeamFragment
            }
        }
    }
`
