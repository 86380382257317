import React, { useState } from 'react'
import "./Philosophy.css";


function Philosophy(props) {
    const data = props;

    return (
        <div className="phil-content container m-auto max-w-4xl py-16 px-4 md:px-0">
            <div dangerouslySetInnerHTML={{__html: props.content }}></div>
        </div>
    )
}

export default Philosophy;